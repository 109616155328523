import React,{useState,useEffect} from "react";
import WOW from "wowjs";

const CoutDown = (props) =>{
    const[days,setDays] = useState('00');
    const[hours,setHours] = useState('00');
    const[minutes,setMinutes] = useState('00');
    const[seconds,setSeconds] = useState('00');

    const newDate = new Date(props.date).getTime();

    useEffect(() => {
        const countdown = () =>{
            const date = new Date().getTime()
            const diff = newDate - date

            const days = Math.floor(diff % (1000 * 60 * 60 * 24 * (365.25 / 12)) / (1000 * 60 * 60 * 24))
            const hours =  Math.floor(diff % (1000 * 60 * 60 * 24) / (1000 * 60 * 60))
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
            const seconds = Math.floor((diff % (1000 * 60)) / 1000)

            setSeconds(seconds < 10 ? '0' + seconds : seconds)
            setMinutes(minutes < 10 ? '0' + minutes :minutes)
            setHours(hours < 10 ? '0' + hours : hours)
            setDays(days < 10 ? '0' + days : days)
        }
    
        const interval = setInterval(() => {
            countdown()
        }, 1000);

        return () => clearInterval(interval);
        
    }, [days, hours, minutes, seconds]);

    
    const [wow, setWow] = useState(new WOW.WOW())
    useEffect(() => {
        setWow(wow.init())
    }, []);

    return(
        <>
            <div className="box wow zoomIn" data-wow-delay="0.4s" data-wow-duration="0.8s">
                <div>
                    <div className="time">{days}</div> 
                    <span>Jours</span> 
                </div>
            </div>
            <div className="box wow zoomIn" data-wow-delay="0.5s" data-wow-duration="0.8s">
                <div>
                    <div className="time">{hours}</div> 
                    <span>Heures</span> 
                </div>
            </div>
            <div className="box wow zoomIn" data-wow-delay="0.6s" data-wow-duration="0.8s">
                <div>
                    <div className="time">{minutes}</div> 
                    <span>Min</span> 
                </div>
            </div>
            <div className="box wow zoomIn" data-wow-delay="0.7s" data-wow-duration="0.8s">
                <div>
                    <div className="time">{seconds}</div> 
                    <span>Sec</span> 
                </div>
            </div>            
        </>
    )
}

export default CoutDown;