import React from 'react';
import Image from 'react-bootstrap/Image';
import "../assets/css/Address.css";
import Title from './Section-Title';


const Address = () => {
    return(
        <>
            <section className="address_section section-padding py-0" id="address">
                <div className="container">
                    <Title sec_title={'Où & Quand'}/>
                    <div className="wpo-event-wrap">
                        <div className="row justify-content-center">
                            <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                <div className="wpo-event-item">
                                    <div className="wpo-event-text">
                                        <h2>Lieu</h2>
                                        <ul>
                                            <li>Blida, Larbaa<br />16:00 – 00:00</li>
                                            <li>La couronne sports et loisirs,<br />Salle Citrine</li>
                                            <li><a className="popup-gmaps" href="https://maps.app.goo.gl/7DQ1RWNkkR5ixYTW8" target="_blank" rel="noreferrer">Voir la localisation</a></li>
                                        </ul>
                                    </div>
                                    <div className="event-shape-1">
                                        <Image src={require('../assets/images/bg/event-shape-1.png')} alt="shape" className='img-fluid'/>
                                    </div>
                                    <div className="event-shape-2">
                                        <Image src={require('../assets/images/bg/event-shape-2.png')} alt="shape" className='img-fluid'/>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                <div className="wpo-event-item">
                                    <div className="wpo-event-text">
                                        <h2>Programme</h2>
                                        <ul>
                                            <li>16:00 Reception</li>
                                            <li>16:30 - 17:30 Café</li>
                                            <li>19:00 - 20:00 Diner</li>
                                            <li>20:00 - 00:00 Soirée</li>
                                        </ul>
                                    </div>
                                    <div className="event-shape-1">
                                        <Image src={require('../assets/images/bg/event-shape-1.png')} alt="image" className='img-fluid'/>
                                    </div>
                                    <div className="event-shape-2">
                                        <Image src={require('../assets/images/bg/event-shape-2.png')} alt="image" className='img-fluid'/>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                <div className="wpo-event-item">
                                    <div className="wpo-event-text">
                                        <h2>Note aux invités</h2>
                                        <ul>
                                            <li>Chers invités, nous vous informons que l'administration n'autorise strictement pas la présence d'enfants.</li>
                                        </ul>
                                    </div>
                                    <div className="event-shape-1">
                                        <Image src={require('../assets/images/bg/event-shape-1.png')} alt="image" />
                                    </div>
                                    <div className="event-shape-2">
                                        <Image src={require('../assets/images/bg/event-shape-2.png')} alt="image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Address;
