import React from 'react';
import Container from 'react-bootstrap/Container';
import '../assets/css/Footer.css';

const Footer = () => {
    return(
        <>
            <footer className="footer">
                <div className="wpo-upper-footer">
                    <Container>
                    <div className="row">
                        <div className="separator"></div>
                        <div className="col col-lg-6 col-md-6 col-sm-6 col-12 mt-3 mt-sm-0">
                            <div className=" widget text-widget">
                                <div className="widget-title">
                                    <h3>Adresse</h3>
                                </div>
                                <div className="contact-ft">
                                    <p>Veuillez cliquer pour suivre la localisation maps.</p>
                                    <ul>
                                        <li><a href="https://maps.app.goo.gl/7DQ1RWNkkR5ixYTW8" target="_blank" rel="noreferrer"><i className="fa fa-map-marker"></i>La couronne sports et loisirs, salle Citrine</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Container>
                </div>
                <div className="wpo-lower-footer  has-not-copyright text-center">
                    <Container>
                        <div className="row">
                            <div className="separator"></div>
                            <div className="col col-xs-12">
                                <p>© Copyright 2024 | <a href="https://itcontrol.io" target="_blank" rel="noreferrer">IT Control</a>, Tous droits résérvés.</p>
                            </div>
                        </div>
                    </Container>
                </div>
            </footer>
        </>
    )
}

export default Footer;
